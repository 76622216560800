export default defineNuxtRouteMiddleware((to, _) => {
  const clinicId = to.params.clinicId

  if (!clinicId || Array.isArray(clinicId)) return
  const clinicIdInt = parseInt(clinicId)
  if (!redirectJson[clinicIdInt]) return

  return navigateTo(
    {
      path: to.path.replace(
        /(clinics\/)([0-9]+)/g,
        `$1${redirectJson[clinicIdInt]}`
      ),
      query: to.query,
    },
    {
      redirectCode: 301,
    }
  )
})

const redirectJson: { [key: number]: number } = {
  12351: 2269,
}
